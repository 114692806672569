import './styles/app.scss';
import './images/logo_de.png';
import './images/logo_fr.png';
import './images/logo_it.png';

import './scripts/_forms';
import './scripts/_screenheight';

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import CharacterCounter from 'stimulus-character-counter'
import { IntersectionController, ToggleClassController } from 'stimulus-library'

window.Stimulus = Application.start()
window.Stimulus.debug = process.env.NODE_ENV === 'development'

const context = require.context("./controllers", true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))
window.Stimulus.register('textarea-autogrow', TextareaAutogrow)
window.Stimulus.register('character-counter', CharacterCounter)
window.Stimulus.register('intersection', IntersectionController)
window.Stimulus.register('toggle-class', ToggleClassController)
