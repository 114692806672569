import { Controller } from '@hotwired/stimulus';

let L;

export default class extends Controller {
    static targets = [
        'map', 'lat', 'lng',
        'street', 'city', 'postalcode',
        'loading', 'error'
    ];
    static values = {
        lat: String,
        lng: String
    };
    static classes = ['loading'];

    connect () {
        if (!this.hasMapTarget) {
            return;
        }

        import('../scripts/_leaflet').then((module) => {
            const lat = this.hasLatTarget ? this.latTarget.value : this.latValue;
            const lng = this.hasLngTarget ? this.lngTarget.value : this.lngValue;

            L = module.default;
            this.map = module.createMap(this.mapTarget, lat, lng);

            const draggable = this.hasLatTarget && this.hasLngTarget;
            this.marker = L.marker([lat, lng], { draggable }).addTo(this.map);

            if (draggable) {
                this.latTarget.setAttribute('readonly', true);
                this.lngTarget.setAttribute('readonly', true);

                this.marker.on('dragend', (event) => {
                    const latLng = event.target.getLatLng();
                    this.latTarget.value = latLng.lat;
                    this.lngTarget.value = latLng.lng;
                });
            }
        }).catch(() => { window.location.reload(true) });
    }

    async search () {
        if (this.hasLoadingTarget && this.hasLoadingClass) {
            this.loadingTarget.classList.add(this.loadingClass);
        }

        if (this.hasErrorTarget) {
            this.errorTarget.hidden = true;
        }

        let data = null;

        if (this.hasStreetTarget) {
            data = await this.searchAdminCh(`${this.streetTarget.value} ${this.postalcodeTarget.value} ${this.cityTarget.value}`);

            if (!data) {
                // Search city + street without postal code
                data = await this.searchAdminCh(`${this.streetTarget.value} ${this.cityTarget.value}`);
            }

            if (!data) {
                data = await this.searchNominatim(`${this.postalcodeTarget.value} ${this.cityTarget.value}, ${this.streetTarget.value}`);
            }
        }

        if (!data) {
            data = await this.searchAdminCh(`${this.postalcodeTarget.value} ${this.cityTarget.value}`);
        }

        if (!data) {
            data = await this.searchNominatim(`${this.postalcodeTarget.value} ${this.cityTarget.value}`);
        }

        if (data) {
            this.latTarget.value = data[0];
            this.lngTarget.value = data[1];

            if (this.hasMapTarget) {
                this.map.setView(data, 16);
                this.marker.setLatLng(new L.LatLng(data[0], data[1]));
            }
        } else if (this.hasErrorTarget) {
            this.errorTarget.hidden = false;
        }

        if (this.hasLoadingTarget && this.hasLoadingClass) {
            this.loadingTarget.classList.remove(this.loadingClass);
        }
    }

    async searchAdminCh (search) {
        const params = new URLSearchParams();
        params.set('type', 'locations');
        params.set('searchText', search);

        const response = await fetch(`https://api3.geo.admin.ch/rest/services/api/SearchServer?${ params.toString() }`, {
            headers: {
                'Accept': 'application/json',
                'User-Agent': 'suchtindex.ch'
            }
        });

        const json = await response.json();

        if (!json.results || !json.results.length) {
            return null;
        }

        return [json.results[0].attrs.lat, json.results[0].attrs.lon];
    }

    async searchNominatim (search) {
        const params = new URLSearchParams();
        params.set('q', search);
        params.set('countrycodes', 'ch,li'); // Limit search to Switzerland and Lichtenstein
        params.set('limit', '1');
        params.set('format', 'json');
        // params.set('email', 'office@infodrog.ch');

        const response = await fetch(`https://nominatim.openstreetmap.org/search?${ params.toString() }`, {
            headers: {
                'Accept': 'application/json',
                'User-Agent': 'suchtindex.ch'
            }
        });

        const json = (await response.json());

        if (!json.length) {
            return null;
        }

        return [json[0].lat, json[0].lon];
    }
}
