import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        load: Boolean,
    };

    connect () {
        if (this.loadValue) {
            this.load();
        }

        window.addEventListener('watchlist-update', this.load.bind(this));
    }

    async load () {
        const url = `${window.location.href}?ids=${this.ids.join(',')}`;

        const response = await fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        this.element.innerHTML = await response.text();

        const shareController = this.application.getControllerForElementAndIdentifier(document.querySelector('.section-subheader__link--share a'), 'share')
        shareController.setUrl(url)
    }

    get ids () {
        return localStorage.getItem('suchtindex-watchlist')?.split(',').filter(id => !!id).map(id => Number(id)).sort() || [];
    }
}
