document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.widget > input.text, .widget > textarea').forEach((el) => {
        if (!el.nextElementSibling || el.nextElementSibling.tagName !== 'LABEL') {
            el.classList.add('no-label');
        }

        if (!el.getAttribute('placeholder')) {
            const detect = () => {
                el.value ? el.classList.remove('no-placeholder') : el.classList.add('no-placeholder');
            };

            el.addEventListener('keyup', detect);
            el.detect = detect;
            detect();
        }
    });

    document.querySelectorAll('.widget > select').forEach((el) => {
        if (el.querySelector('option').value === '') {
            return;
        }

        const update = () => {
            el.classList[el.value ? 'remove' : 'add']('empty');
        };

        el.addEventListener('change', update);
        update();
    });
});
