window.addEventListener('DOMContentLoaded', () => {
    const initScreenHeight = () => {
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    let timeout = false;
    window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(initScreenHeight, 250);
    })
    initScreenHeight();
});
