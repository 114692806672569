import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        title: String,
        i18n: {
            type: Object,
            default: {
                close: 'Fenster schliessen',
                copy: 'Link kopieren',
                title: 'Link in die Zwischenablage kopieren',
                success: 'Kopiert!',
            }
        }
    }

    initialize () {
        this.share = this.share.bind(this);
    }

    connect () {
        this.element.addEventListener('click', this.share);
    }

    disconnect () {
        this.element.removeEventListener('click', this.share);
    }

    async share (event) {
        event.preventDefault();

        if (navigator.share) {
            try {
                await navigator.share({ url: this.getUrl() });
                return;
            } catch (err) {
                if (err.name === 'AbortError') {
                    return;
                }
            }
        }

        const portal = document.createElement('div');
        portal.classList.add('share');
        portal.innerHTML = `
        <div class="share__overlay"></div>
        <div class="share__content">
            <button type="button" class="share__close" title="Fenster schliessen"><svg width="13.017" height="13.017"><g fill="none" stroke="gray" stroke-width="1.3"><path d="M.46 12.557 12.48.537"/><path d="M12.558 12.48.537.459"/></g></svg></button>
            <p class="share__title">${this.titleValue}</p>
            <p class="share__link"><code>${this.getUrl()}</code></p>
            <div class="share__actions"><button type="button" class="share__clipboard" title="${this.i18nValue.title}">${this.i18nValue.copy}</button></div>
        </div>
        `;

        document.body.append(portal);
        document.body.style.overflow = 'hidden';

        const close = () => {
            portal.remove();
            document.body.style.overflow = '';
        };

        document.querySelector('.share__overlay').addEventListener('click', close);
        document.querySelector('.share__close').addEventListener('click', close);

        const clipboard = document.querySelector('.share__clipboard');
        clipboard.addEventListener('click', () => {
            this.copyToClipboard();
            clipboard.innerText = this.i18nValue.success;
            setTimeout(close, 1000);
        });
    }

    setUrl (value) {
        this.url = value;
        this.element.href = value;
    }

    getUrl () {
        return this.url || location.href;
    }

    copyToClipboard () {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(this.getUrl()).catch(this.clipboardFallback.bind(this));
        } else {
            this.clipboardFallback();
        }
    }

    clipboardFallback  () {
        const input = document.createElement('input');
        input.value = this.getUrl();
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(input);
    }
}
