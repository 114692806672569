import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['active'];
    static values = {
        id: Number,
        addTitle: String,
        removeTitle: String,
    };

    connect () {
        this.updateToggle();
    }

    toggle (event) {
        event.preventDefault();
        const id = this.idValue;
        const ids = this.ids;
        const exists = ids.includes(id);

        if (exists) {
            this.ids = ids.filter(i => i !== id);
        } else {
            ids.push(id);
            this.ids = ids;
        }

        this.updateToggle();
        window.dispatchEvent(new Event('watchlist-update'));
    }

    remove (event) {
        event.preventDefault();
        const id = Number(event.params.id);

        this.ids = this.ids.filter(i => i !== id);
        window.dispatchEvent(new Event('watchlist-update'));
    }

    updateToggle () {
        const exists = this.ids.includes(this.idValue);

        if (this.hasRemoveTitleValue && this.hasAddTitleValue) {
            this.element.title = exists ? this.removeTitleValue : this.addTitleValue;
        }

        if (this.hasActiveClass) {
            this.element.classList[exists ? 'add' : 'remove'](this.activeClass);
        }
    }

    get ids () {
        return localStorage.getItem('suchtindex-watchlist')?.split(',').filter(id => !!id).map(id => Number(id)).sort() || [];
    }

    set ids (value) {
        localStorage.setItem('suchtindex-watchlist', value.join(','));
    }
}
