import { Controller } from '@hotwired/stimulus';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

export default class extends Controller {
    static targets = ['link'];

    connect () {
        this.$glightbox = GLightbox({
            autoplayVideos: true,
            autofocusVideos: true,
            elements: this.linkTargets,
        });
    }

    disconnect () {
        this.$glightbox?.destroy();
    }

    open () {
        this.$glightbox.open();
    }
}
