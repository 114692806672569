import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['update'];

    connect () {
        this.updateCount(false);
        window.addEventListener('watchlist-update', this.updateCount.bind(this));
    }

    updateCount (animate = true) {
        if (animate && this.hasUpdateClass) {
            this.element.classList.remove(this.updateClass);
        }

        setTimeout(() => {
            this.element.innerText = this.ids.length || '';

            if (animate && this.hasUpdateClass) {
                this.element.classList.add(this.updateClass);
            }
        }, 50);
    }

    get ids () {
        return localStorage.getItem('suchtindex-watchlist')?.split(',').filter(id => !!id).map(id => Number(id)).sort() || [];
    }
}
