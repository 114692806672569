import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect () {
        if (!window.opener) {
            return;
        }

        this.element.addEventListener('click', (e) => {
            e.preventDefault();
            open(location, '_self').close();
        });
    }
}
